*{margin:0;padding:0}
body{font-size: 14px;background-color:#444;font-size: 16px;}

.pr0{padding-right: 0;}
.pl0{padding-left: 0;}
.wrapper{width: 900px;margin: 50px auto 40px;display: flex;}
.sidebar{background: #4b5169;border-top-left-radius: 4px;border-bottom-left-radius: 4px;flex-basis: 200px;flex-shrink: 0;}
.logo-img{padding: 20px;}
.logo-img img{width: 100px;;}
.signinleft{background: #e4e4e4;padding: 20px 20px 50px;border-top-left-radius: 6px;border-bottom-left-radius: 6px;}
.signinleft .logo{width: 100px;margin-right: 10px;}
.signinright{background: #fff;padding: 40px 20px;border-top-right-radius: 6px;border-bottom-right-radius: 6px;}

.sign-left-headding{font-size: 26px;font-weight: bold;text-transform: uppercase;margin-bottom: 20px;}
.dial-cta{background:#000;border-radius: 6px;padding: 10px 20px;color: #fff;}
.dial-block{display: block; margin-bottom: 20px;}
.dial-block p{font-size: 18px;line-height: 1.4;padding-top: 10px;}
.dial-block span{margin:10px 0px;display: inline-block  ;}
.helping-txt{font-size: 18px;}
.signin-headding{text-align: center;margin-bottom: 20px;font-size: 30px;}
.signinright .formelement-block{margin-bottom: 20px;line-height: 15px;}
.error-msg{color: #f00;font-size: 12px;padding-top: 5px;}
.forgot-txt{font-size: 16px;}
.sign-btn{display: block;width: 100%;padding: 10px 0;margin-bottom: 10px;}
.signinright button[type=button] + p{text-align: center;}
.signinright .form-group:nth-of-type(2) + p{text-align: center}
.lineHeight{line-height: 15px;}
/* Header Starts Here */


/* Signup start here */

.signup-bg{background: #e4e4e4;width: 100%;padding: 38px;border-bottom-right-radius: 8px;}
.signup-bg h2{text-align: center;margin-bottom: 20px;font-weight: bold;}
.signup-bg label{margin-bottom: 0;}
.signup-bg button[type=button] + p{text-align: center;width: 100%;}

.submit-btn{width: 200px;}
.pass-reset{width: 400px;padding-top: 70px}
.pass-reset p{text-align: center;}
.signup-bg h2 img{width: 80px;margin-right: 10px;}

/* Header starts here */
.header{background:#222;width: 100%;height: 90px;}
.header-inner{display: flex;width: 900px;background:#222;justify-content: space-between;align-items: center;height: 90px;margin: 0 auto;}
.header .logo img{width: 70px;}
.right-menu{display: flex;list-style-type: none;margin: 0;}
.right-menu li{padding-right: 20px;}
.right-menu li a{color: #ccc;}

.footer{background: #222;text-align: center;width: 100%;font-size: 12px;color: #cdcdcd;padding: 10px 0;
    position: fixed;bottom:0;left:0;}
    
.container-bg{background: #e4e4e4;padding: 40px;border-bottom-right-radius: 6px;}
.wel-txt{font-size: 32px;text-align: center;font-weight: bold;}
.rank-card-block{display: flex;align-items: center;width: 100%;overflow: hidden;}
.rank-block{width: 150px;height: 150px;background: #fff;border: 2px solid #cdcdcd;display: flex;justify-content: center;align-items: center;font-size: 32px;
 font-weight: bold;padding: 20px;text-align: center;margin-right: 50px;}
.finish-set-txt{border-radius: 50px; background:#444;color: #fff;padding: 10px 20px;font-size: 20px;text-align: center;}
.rank-card-block h4{font-size: 32px;}
.custom-btn-block{width: 100%;text-align: center;}
.custom-btn{background: #707070;border-radius: 6px;padding: 5px 10px;text-align: center;color: #fff;;}
.custom-btn-large{background: #707070;border-radius: 50px;padding: 10px 20px;text-align: center;color: #fff;width: 300px;font-size: 18px;}
.custom-btn:hover{color: #fff;}
.custom-btn-large:hover{color: #fff;}
.custom-btn-block .custom-btn:nth-of-type(1){margin-right: 20px;}
.training-headding{padding-top: 20px;font-weight: bold;}
.assess-common-txt{text-align: center;font-size: 18px;padding: 20px 0;}
.ptbnt{padding-top: 40px;padding-bottom: 40px;}

.nav li a, .nav li a:hover{color: #fff;}
textarea.form-control {
    resize: none;
}
.nav{justify-content: left;}

.submit-btn{color: #fff;background-color: #007bff;border: none; width: 200px;padding: 10px 0;border-radius: 4px;margin-top: 20px;}
.submit-btn:focus{box-shadow: none;}
.submit-btn:hover{color: #fff;}
.main-panel{flex-basis: 90%;}
.err{font-size: 12px;color: #f00;}

#show{display: none;position: fixed;top: 50%;background: #fff;border-radius: 8px;height: 150px;width: 400px;left: 50%;
text-align: center;padding: 50px 10px;z-index: 99;box-shadow: 0px 2px 4px #888888;font-size: 16px;transform: translate(-50%, -50%);}
#show1{display: none;position: fixed;top: 50%;background: #fff;border-radius: 8px;height: 150px;width: 400px;left: 50%;
    text-align: center;padding: 50px 10px;z-index: 99;box-shadow: 0px 2px 4px #888888;font-size: 16px;transform: translate(-50%, -50%);}
#show a{background:#222;border-radius: 4px;color:#fff;padding:2px 20px;margin-top: 10px;display: inline-block;}
#show a:hover{text-decoration: underline;}
#show1 a{background:#222;border-radius: 4px;color:#fff;padding:2px 20px;margin-top: 10px;display: inline-block;}
#show1 a:hover{text-decoration: underline;}
.active{color: #A4F3F1 !important;}

.forgot-radious{border-radius: 6px;}

#errorMessage{color: #f00;font-size: 12px;}
#errorMessage1{color: #f00;font-size: 12px;}
#errorMessage2{color: #f00;font-size: 12px;}
#errorMessage3{color: #f00;font-size: 12px;}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
  }
  .bg-light{border-radius: 0 6px 0 0;}
  .sign-border{border-radius: 6px;}

.admin-menu{list-style: none;}
.admin-menu li {display: inline;}
.admin-wrapper{height: 500px;text-align: center;}
.admin-wrapper ul{width: 90%;margin: 40px auto;}
.admin-wrapper ul li{margin-right: 10px;}
.admin-wrapper ul li a{background:#007bff;padding: 10px 10px;border-radius: 6px;color: #fff ;}
.admin-wrapper ul li a:hover{text-decoration: none;}

.left-pannel{width: 60%;background: #4b5169;padding-left: 20px;padding-top: 40px;}
.left-pannel ul li{padding-bottom:20px;font-size: 16px;}
.left-pannel ul li a{color: #fff;}
.left-pannel ul li a:hover{color:#62cae8;text-decoration: none;}
.left-pannel ul li a.active{color:#62cae8}
.right-pannel{padding: 20px;}
.update-btn{text-align: center;}
.rank-code{font-size: 18px;font-weight: bold;width: 100%;display: block;}